import toast, { Toaster } from 'react-hot-toast';


export const SuccessToaster = (msg) => {
  return toast.success(msg, {
    duration: 3000,
    position: 'top-right',
    style: {
      backgroundColor: '#2e2e97',
      color: '#fff',
      zIndex:999999
    }
  })
}

export const ErrorToaster = (msg) => {
  return toast.error(msg, {
    duration: 3000,
    position: 'top-right',
    style: {
      backgroundColor: 'red',
      color: '#fff',
      zIndex:999999
    },
    icon:null
  })
}