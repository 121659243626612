import React, { useEffect, useState } from "react";
import { FetchOrderlist } from "../APIS/AuthApis";
import { connect } from "react-redux";
import OrderDetail from "./OrderDetail";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CurrencyFormat from "../Utils/CurrencyFormat";

const MyOrder = ({
  getSelectedLanguage,
  setShow,
  setorderId,
  setOrderNumber,
}) => {
  const [order, setOrder] = useState([]);
  const [orderLength, setOrderLength] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const npage = Math.ceil(order.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      language: getSelectedLanguage,
      page: currentPage,
    };
    FetchOrderlist(Relove_Token, formdata, (callback) => {
      setOrderLength(callback.total_orders);
      setOrder(callback.data.orders);
    });
  }, [currentPage]);

  const perPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const changeCpage = (n) => {
    setCurrentPage(n);
  };
  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handleView = (track, orderId, orderNumber) => {
    setShow(track);
    setorderId(orderId);
    setOrderNumber(orderNumber);
  };
  const { t } = useTranslation();

  return (
    <div className="table-responsive myorders">
      <style>{`
        .table td, .table th {
          vertical-align: middle;
        }
      `}</style>
      <table className="table align-middle">
        <thead className="table-light">
          <tr style={{ fontSize: "12px", textAlign: "center" }}>
            <th scope="col">{t("SNo")}</th>
            <th scope="col">Order number</th>
            <th scope="col">Price</th>
            <th scope="col">{t("Image")}</th>
            <th scope="col">{t("Quantity")}</th>
            <th scope="col">{t("Status")}</th>
            <th scope="col">{t("Date")}</th>
            <th scope="col">{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {order.map((item, index) => {
            const {
              orderId,
              order_number,
              quantity,
              featured_image,
              sale_price,
              createdAt,
              status,
            } = item;
            return (
              <tr key={index} style={{ fontSize: "12px", textAlign: "center" }}>
                <th scope="row">{index + 1}</th>
                <td>{order_number}</td>
                <td> <CurrencyFormat amount={sale_price} /></td>
                <td className="d-flex justify-content-center">
                  <div className="order-table-image ">
                    <img src={featured_image} alt="image-order" />
                  </div>
                </td>
                <td>{quantity}</td>
                <td>
                  <p className="d-flex justify-content-center align-items-center mt-3 order-table-status">
                    {status === 3 ? "Cancel" : status === 2 ? "Stripe" : "Razorpay"}
                  </p>
                </td>
                <td>{createdAt}</td>
                <td>
                  <p
                    className="d-flex justify-content-center align-items-center mt-3 order-table-status"
                    onClick={() => handleView("track", orderId, order_number)}
                  >
                    View
                  </p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {orderLength > 10 && (
        <nav>
          <ul className="pagination d-flex justify-content-end">
            <li className="page-item">
              <NavLink
                to=""
                className={`page-link${currentPage === 1 ? " back" : ""}`}
                onClick={perPage}
              >
                {currentPage === 1 ? "" : <>{t("PrevPage")}</>}
              </NavLink>
            </li>
            {numbers.map((n, i) => (
              <li
                className={`page-item ${currentPage === n ? "active" : ""}`}
                key={i}
              >
                <NavLink
                  to=""
                  className="page-link"
                  onClick={() => changeCpage(n)}
                >
                  {n}
                </NavLink>
              </li>
            ))}
            <li className="page-item">
              <NavLink
                to=""
                className={`page-link${currentPage === numbers.length ? " next" : ""
                  }`}
                onClick={nextPage}
              >
                {currentPage === numbers.length ? "" : <>{t("NextPage")}</>}
              </NavLink>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSelectedLanguage: state.language.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MyOrder);
