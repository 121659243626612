import React, { useEffect, useLayoutEffect, useState } from "react";
import "./../Styles/AddrerssItem.css";
import Topbar from "../Sections/Topbar";
import Navbar from "../Sections/Navbar";
import Footer from "../Sections/Footer";
import { connect } from "react-redux";
import { setCartLength, setSingleAddress } from "../Redux/actions";
import { FetchCartApi, UpdateCartApi, deleteCartApi } from "../APIS/cartApis";
import { FetchAddressApi, DeleteAddressApi, orderCreate, payRazorPayApi } from "../APIS/apis";
import { ErrorToaster } from "../Toaster";
import { AiOutlinePlus, AiTwotoneDelete } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { shippingApi } from "../APIS/apis";
import EditAddressModal from "../Modals/EditAddressModal";
import NewAddressModal from "../Modals/NewAddressModal";
import CurrencyFormat from "../Utils/CurrencyFormat";


const AddrerssItem = ({
  getSelectedLanguage,
  setCartLengthRedux,
  setSingleAddressKey,
}) => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [allCartDate, setAllCartData] = useState([]);
  const [updateCartPage, setUpdateCartPage] = useState(false);
  const [address, setAddress] = useState({});
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [Edit, setEdit] = useState([]);
  const [paymentType, setPaymentType] = useState(" ");
  const [DelvieryAddress, setDelvieryAddress] = useState("");
  const [shippingPrice, setShippingPrice] = useState(0);
  const [shipRates, setShipRates] = useState([]);
  const [isScrolled, setIsScrolled] = useState(false);
  const [openDeliveryAddress, setOpenDeliveryAddress] = useState(true)
  const [openOrderSummary, setOpenOrderSummary] = useState(false)
  const [openPaymentOptions, setOpenPaymentOptions] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState(null)
  console.log("openDeliveryAddress", openDeliveryAddress, "openOrderSummary", openOrderSummary, "openPaymentOptions", openPaymentOptions, selectedAddress)

  const handleOpen1 = (item) => {
    setEdit(item);
    setOpen1(true);
  };

  const handleOpen = () => setOpen(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [])

  useLayoutEffect(() => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      language: getSelectedLanguage,
    };
    FetchCartApi(Relove_Token, formdata, (callbackData) => {
      setAllCartData(callbackData);
      setCartLengthRedux(callbackData?.data?.cart_items?.length);
    });
  }, [updateCartPage, getSelectedLanguage]);


  const handleDeleteCart = (cartId) => {
    if (allCartDate?.data?.cart_items?.length == 1) {
      ErrorToaster("Atleast one product is necessary for checkout")
      return;
    }
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      cartId: cartId,
      language: getSelectedLanguage,
    };
    deleteCartApi(Relove_Token, formdata, updateCartPage, setUpdateCartPage);
  };

  const updateCartHandle = (cartId, productId, variationId, quantity) => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      cartId: cartId,
      productId: productId,
      variationId: variationId,
      quantity: quantity,
      language: getSelectedLanguage,
    };
    UpdateCartApi(Relove_Token, formdata, updateCartPage, setUpdateCartPage);
  };

  useEffect(() => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      language: getSelectedLanguage,
    };
    FetchAddressApi(Relove_Token, formdata, (callback) => {
      setAddress(callback?.data);
    });
  }, [getSelectedLanguage]);

  const handleRemove = (addressId) => {
    if (addressId == DelvieryAddress) {
      setDelvieryAddress("")
    }
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      language: getSelectedLanguage,
    };
    DeleteAddressApi(
      Relove_Token,
      addressId,
      FetchAddressApi,
      formdata,
      (callback) => {
        setAddress(callback?.data);
      }
    );
  };

  let iteam = allCartDate?.data?.cart_items?.map((item, i) => {
    return {
      quantity: item.quantity,
      variationId: item.variationId,
      productId: item.productDetails[0].id,
      base_price: item.variationData.base_price,
      sale_price: item.variationData.sale_price,
      sku: item.variationData.sku,
    };
  });

  let payment = allCartDate?.data?.couponInfo?.map((data, i) => {
    return {
      couponId: data.couponId,
      discountAmount: data.discountAmount,
      totalAmount: data.subtotalAmount,
      payAmount: data.grandTotal,
    };
  });

  const payItem = {
    addressId: DelvieryAddress,
    discountAmount: payment?.length >= 0 ? payment[0]?.discountAmount : 0,
    totalAmount: payment?.length >= 0 ? payment[0]?.totalAmount : "",
    payAmount: payment?.length >= 0 ? payment[0]?.payAmount : "",
    shippingAmount: shippingPrice,
    payment_method: paymentType,
    billing_shipping_address: DelvieryAddress,
    item: iteam,
  };


  const handleOrdercreate = () => {
    let Relove_Token = localStorage.getItem("Relove_Token");

    if (allCartDate?.data?.length !== 0 && DelvieryAddress !== "") {
      orderCreate(Relove_Token, payItem, navigation, setCartLengthRedux);
    } else if (allCartDate?.data?.length === 0) {
      ErrorToaster("Your Cart is Empty");
    } else {
      ErrorToaster("Please select delivery address before payment");
    }
    setSingleAddressKey(DelvieryAddress);
  };


  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    if (allCartDate?.data?.cart_items?.length !== 0 && DelvieryAddress !== "") {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      const pincode = localStorage.getItem('pincode')
      let Relove_Token = localStorage.getItem("Relove_Token");

      const data = {
        amount: allCartDate?.data?.couponInfo[0].grandTotal + shippingPrice,
        pincode: pincode
      }

      payRazorPayApi(Relove_Token, data, (result => {
        if (!result) {
          alert("Server error. Are you online?");
          return;
        }
        // Getting the order details back

        console.log("result of razaorpay", result)
        const { amount, order_id, key } = result;

        const options = {
          key: key, // Enter the Key ID generated from the Dashboard
          amount: amount,
          currency: 'INR',
          name: "Hj Hasasi",
          description: "Test Transaction",
          // image: { logo },
          order_id: order_id,
          handler: async function (response) {
            console.log("IN handler", response)
            const data = {
              // orderCreationId: order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
              ...payItem
            };

            console.log('dataaaaaaaaa', data)

            orderCreate(Relove_Token, data, navigation, setCartLengthRedux)
          },
          prefill: {
            name: "Hj Hasasi",
            // email: "support@hasasidenim.com",
            // contact: "87799 58981",
          },
          notes: {
            address: "9th Floor, Unit No. 914, Wing C & D, Shiv Solitaire, Jogeshwari Station Road, Jogeshwari East, Mumbai - 400060",
          },
          theme: {
            color: "#61dafb",
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      }))
    } else if (allCartDate?.data?.cart_items.length === 0) {
      ErrorToaster("Your Cart is Empty");
    } else {
      ErrorToaster("Please select delivery address before payment");
    }

  }

  const handleshippingrate = (id, destinationPincode) => {
    console.log(DelvieryAddress, id)
    if (id !== DelvieryAddress) {
      let Relove_Token = localStorage.getItem("Relove_Token");
      localStorage.setItem("pincode", destinationPincode)
      const data = {
        md: "E",
        cgm: 0,
        o_pin: 400060,
        d_pin: destinationPincode,
        ss: "Delivered",
        pt: "Pre-paid"
      }
      shippingApi(Relove_Token, data, (callback) => {
        if (callback && callback.data && callback.data[0]) {
          setShippingPrice(Math.round(callback.data[0].total_amount));
          setOpenDeliveryAddress(false);
          setOpenOrderSummary(true);
          setDelvieryAddress(id);
        }
      });
    }
  };

  const capitalize = (str) => (str ? str.charAt(0).toUpperCase() + str.slice(1) : '');


  return (
    <>
      <NewAddressModal
        open={open}
        setOpen={setOpen}
        setAddress={setAddress}
      />
      <EditAddressModal
        open1={open1}
        setOpen1={setOpen1}
        setAddress={setAddress}
        Edit={Edit}
      />
      <div className={`top-bars ${isScrolled ? "scrolled" : ""}`}>
        <Topbar />
        <Navbar />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 p-lg-0">
            <h3 className="mt-3 mb-0 fw-bold">Checkout</h3>
          </div>
        </div>
      </div>
      <div class="container checkout my-4">
        <div class="row">
          <div class="col-md-8 p-sm-0">
            <div id="accordion">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <h5 class="mb-0 d-flex align-items-center justify-content-between">
                    <button
                      className="btn btn-link"
                      // data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded={openDeliveryAddress}
                      aria-controls="collapseOne"
                      disabled={!openDeliveryAddress}
                    // onClick={() => setOpenDeliveryAddress(!openDeliveryAddress)}
                    >
                      1. Delivery Address
                    </button>
                    {
                      DelvieryAddress && !openDeliveryAddress && <button
                        className="btn btn-dark right-button-address mb-2"
                        onClick={() => { setOpenPaymentOptions(false); setOpenOrderSummary(false); setOpenDeliveryAddress(true) }}
                        style={{ fontSize: "14px" }}
                      >
                        Change
                      </button>
                    }
                    {
                      DelvieryAddress && openDeliveryAddress && <button
                        className="btn btn-dark right-button-address"
                        onClick={() => { setOpenPaymentOptions(false); setOpenOrderSummary(true); setOpenDeliveryAddress(false) }}
                        style={{ fontSize: "14px" }}
                      >
                        Deliver Here
                      </button>
                    }
                  </h5>
                  {
                    DelvieryAddress && <div className="selectedAddress">
                      <span>{capitalize(selectedAddress?.name)}</span>
                      <span>({capitalize(selectedAddress?.address_type)}),</span>
                      <span>{" "}{capitalize(selectedAddress?.mobile)},</span>
                      <span>{" "}{capitalize(selectedAddress?.landmark)},</span>
                      <span>{" "}{capitalize(selectedAddress?.street)},</span>
                      <span>{" "}{capitalize(selectedAddress?.city)},</span>{" "}
                      <span>{" "}{capitalize(selectedAddress?.state)}-</span>
                      <span>{" "}{selectedAddress?.code}</span>
                    </div>
                  }
                </div>

                <div id="collapseOne" className={`collapse ${openDeliveryAddress ? 'show' : ''}`} aria-labelledby="headingOne" data-parent="#accordion">
                  <div class="card-body">
                    <div className="adreress-right">
                      <div className="d-flex justify-content-between align-items-center adreress-right-heading">
                        <h6>Select Address</h6>
                        <button
                          className="btn btn-dark right-button-address"
                          onClick={handleOpen}
                        >
                          {t("AddNew")}
                        </button>
                      </div>
                      <div className="row pb-md-0 pb-2">
                        <div className="col-12">
                          <div className="add-right-check-box">
                            {address && address?.addressList && address?.addressList?.length > 0 ? (
                              <>
                                {address?.addressList?.map((item, i) => {
                                  return (
                                    <div className="right-address-border" key={item?.id}>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input formcheckinput-right"
                                          type="radio"
                                          name="DelvieryAddress"
                                          id={`DelvieryAddress${i}`}
                                          onClick={() => { handleshippingrate(item?.id, item?.code); setSelectedAddress(item) }
                                          }
                                          checked={DelvieryAddress === item?.id}
                                        />
                                        <div className="ms-3 address-right-item">
                                          <div>
                                            <span>{capitalize(item?.name)}</span>
                                            <span>({capitalize(item?.address_type)})</span>
                                          </div>
                                          <div>
                                            <span>{capitalize(item?.mobile)}</span>
                                          </div>
                                          <div>
                                            <span>{capitalize(item?.landmark)}</span>
                                          </div>
                                          <div>
                                            <span>{capitalize(item?.street)}</span>
                                          </div>
                                          <div>
                                            <span>{capitalize(item?.city)}</span>{" "}
                                            <span>{capitalize(item?.state)}</span>
                                          </div>
                                          <div>
                                            <span>{t("PinCodeCheckout")}</span>
                                            <span>{item?.code}</span>
                                          </div>
                                          <div className="mt-3">
                                            <button
                                              className="btn btn-dark right-button-address"
                                              onClick={() => { handleOpen1(item); setDelvieryAddress(""); setShippingPrice(0) }}
                                            >
                                              {t("Edit")}
                                            </button>
                                            <button
                                              className="btn btn-dark ms-3 right-button-address"
                                              onClick={() => handleRemove(item.id)}
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              <div className="fw-bold d-flex justify-content-center align-items-center">
                                You have no address added
                              </div>
                            )}
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h5 class="mb-0  d-flex align-items-center justify-content-between">
                    <button
                      className="btn btn-link"
                      // data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded={openOrderSummary} // Open if the state is true
                      aria-controls="collapseTwo"
                      disabled={!openOrderSummary}// Disable if no address is selected
                    >
                      2. Order Summary
                    </button>
                    {
                      DelvieryAddress && !openOrderSummary && <button
                        className="btn btn-dark right-button-address"
                        onClick={() => { setOpenPaymentOptions(false); setOpenOrderSummary(true); setOpenDeliveryAddress(false) }}
                        style={{ fontSize: "14px" }}
                      >
                        Change
                      </button>
                    }
                  </h5>
                </div>
                <div id="collapseTwo" className={`collapse ${openOrderSummary ? 'show' : ''}`} aria-labelledby="headingTwo" data-parent="#accordion">
                  <div class="card-body">
                    <div className="adreress-left">
                      <h6 className="my-3">Your Products</h6>

                      {allCartDate?.data?.cart_items?.map((item, i) => {
                        return (
                          <div className="row address-left-border-bottom" key={i}>
                            <div className="col-4" id="address-left-border">
                              <div className="address-left-image">
                                <img
                                  src={item?.productDetails[0]?.imagesGet.length ? item?.productDetails[0]?.productsVariationsImages[0] : item?.productDetails[0]?.featured_image}
                                  alt="checkout"
                                />
                              </div>
                            </div>
                            <div className="col-8" id="address-left-border-1">
                              <div className="address-left-side ">
                                <p className="cart-mobile-name mb-1 addres-left-heading" style={{
                                  display: '-webkit-box',
                                  WebkitBoxOrient: 'vertical',
                                  WebkitLineClamp: 2,
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'normal'
                                }}> {item?.variationData?.variation_name}</p>
                                <p className="cart-mobile-variation-name mb-1 d-flex align-items-center addres-left-heading">{item?.variationData?.variations[0].attributeName}{" "}<p className="mb-0 me-2"> : </p> <p className="m-0 ms-2" style={{ fontWeight: "400" }}>{item?.variationData?.variations[0].variationName}</p></p>
                                <p className="cart-mobile-variation-name mb-1 d-flex align-items-center addres-left-heading">{item?.variationData?.variations[1].attributeName}{" "}<p className="mb-0 me-2"> : </p> <p className="m-0 ms-2" style={{ fontWeight: "400" }}>{" "}{item?.variationData?.variations[1].variationName}</p></p>
                              </div>
                              <div className="d-flex address-left-side">
                                <p className="fs-blod text-black me-2 addres-left-heading">
                                  {t("Pricesmallcart")}
                                  <span>:</span>
                                </p>
                                <p>
                                  {""}
                                  <CurrencyFormat amount={item?.variationData?.sale_price} />
                                </p>
                              </div>
                              {shipRates.length > 0 ? <div className="d-flex address-left-side">
                                <p className="fs-blod text-black me-2 addres-left-heading">
                                  {t("ShipingPrice")}
                                  <span className="me-2">:</span>
                                </p>
                                <p>
                                  {""}
                                  {t("₹")} {shipRates[i]}
                                </p>
                              </div> : ""}
                              <div className="d-flex address-left-side">
                                <p className="fs-blod text-black addres-left-heading">
                                  {t("SubTotal")}
                                  <span className="me-2">:</span>
                                </p>
                                {shipRates.length > 0 ? <p>
                                  {/* {t("₹")}{" "} */}
                                  <CurrencyFormat amount={item?.variationData?.sale_price * item?.quantity} />
                                  {/* {item?.variationData?.sale_price * item?.quantity} */}
                                </p> : <p>
                                  <CurrencyFormat amount={item?.variationData?.sale_price * item?.quantity} />
                                </p>}

                              </div>
                              <div className="d-flex align-items-center justify-content-between checkoutquntity mt-2">
                                <AiOutlineMinus
                                  className="pointer"
                                  onClick={() =>
                                    updateCartHandle(
                                      item.cartId,
                                      item?.productDetails[0]?.id,
                                      item?.variationId,
                                      parseInt(item?.quantity) - 1
                                    )
                                  }
                                />
                                <p className="m-0">{item?.quantity}</p>
                                <AiOutlinePlus
                                  className="pointer"
                                  onClick={() =>
                                    updateCartHandle(
                                      item.cartId,
                                      item?.productDetails[0]?.id,
                                      item?.variationId,
                                      parseInt(item?.quantity) + 1
                                    )
                                  }
                                />
                              </div>
                              <div className=" d-flex align-items-center my-2 checkoutRemove mb-0" onClick={() => handleDeleteCart(item.cartId)}>
                                <p>{t("Removesmallcart")}</p>
                                <p className="ms-1">
                                  {" "}
                                  <AiTwotoneDelete

                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      <div className="d-flex justify-content-end">
                        <button
                          className="btn btn-dark right-button-address"
                          onClick={() => { setOpenPaymentOptions(true); setOpenOrderSummary(false); setOpenDeliveryAddress(false) }}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="headingThree">
                  <h5 class="mb-0">
                    <button
                      className="btn btn-link"
                      // data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded={openPaymentOptions} // Open if the state is true
                      aria-controls="collapseThree"
                      disabled={!openPaymentOptions}// Disable if no address is selected
                    >
                      3. Payment Options
                    </button>
                  </h5>
                </div>
                <div id="collapseThree" className={`collapse ${openPaymentOptions ? 'show' : ''}`} aria-labelledby="headingThree" data-parent="#accordion">
                  <div class="card-body">
                    <div className="paymant-box mt-3">
                      <div>
                        <div className="paymant-box-headding">
                          <h6 className="m-sm-0 mb-3">
                            {t("PaymantCheckout")}
                          </h6>
                        </div>
                        <div className="d-sm-flex">
                          <div className="form-check formcheck-paytam d-flex align-items-center">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault2"
                              onClick={(e) => {
                                setPaymentType("cod");
                              }}
                              checked={paymentType === "cod" ? true : false}
                            />
                            <label
                              className="form-check-label mt-1"
                              for="flexRadioDefault1"
                            >
                              {t("CashDelivery")}
                            </label>
                          </div>
                          <div className="form-check formcheck-paytam d-flex align-items-center ms-sm-5">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault3"
                              onClick={(e) => {
                                setPaymentType("razorpay");
                              }}
                              checked={paymentType === "razorpay" ? true : false}
                            />
                            <label
                              className="form-check-label"
                              for="flexRadioDefault2"
                            >
                              <img src="/img/Razor1.png" alt="razorpay" height={"80px"}></img>
                            </label>
                          </div>
                        </div>
                      </div>
                      {paymentType === "cod" ? (
                        <div className="pay-button">
                          <button
                            className="btn btn-dark"
                            onClick={handleOrdercreate}
                          >
                            {t("BuyCheckout")}
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                      {paymentType === "razorpay" ? (
                        <div className="pay-button">
                          <button className="btn btn-dark" onClick={displayRazorpay}>
                            {t("PayCheckout")}
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="order-summary">
              <h5>Price Details</h5>
              <hr />
              {allCartDate?.data?.couponInfo?.map((item, i) => {
                return (
                  <div class="price-details">
                    <p>Sub Total <span><CurrencyFormat amount={item?.subtotalAmount} /></span></p>
                    <p>Shipping Charges <span><CurrencyFormat amount={shippingPrice} /></span></p>
                    <hr />
                    <p><strong>Total Payable</strong> <span><strong><CurrencyFormat amount={item?.grandTotal + shippingPrice} /></strong></span></p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    getSelectedLanguage: state.language.language,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCartLengthRedux: (parameter) => {
      dispatch(setCartLength(parameter));
    },
    setSingleAddressKey: (parameter) => {
      dispatch(setSingleAddress(parameter));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddrerssItem);
