import React from 'react';

const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 2
    }).format(amount);
};

const CurrencyFormat = ({ amount }) => {
    const formattedAmount = formatCurrency(amount);

    return (
        <>
            {formattedAmount}
        </>
    );
};

export default CurrencyFormat;
