import Routes from "./Routes/Routes";
import { useLayoutEffect } from "react";
import { Toaster } from "react-hot-toast";
import i18next from "i18next";
import { connect } from "react-redux";
import { setSelectedLanguages, setUserDetail } from "./Redux/actions";
import "./App.css";
import { useEffect } from "react";
import { setUserAuthentication } from "./Redux/actions";
import { fetchUserDetailsApi } from "./APIS/AuthApis";
import { FetchCartApi } from "./APIS/cartApis";
import { setCartLength } from "./Redux/actions";
import { FetchWishlistApi } from "./APIS/WishlistApis";
import { setWishLength } from "./Redux/actions";
import { CoupanFetch } from "./APIS/CoupanApi";
import { setOffersDetails } from "./Redux/actions";

function App({
  setSelectedLanguagesRedux,
  getUserAuthenticationRedux,
  setUserDetailRedux,
  setCartLengthRedux,
  setUpdateProductSetWishLength,
  setOffers,
}) {
  let languageStorage = localStorage.getItem("language");

  useLayoutEffect(() => {
    let languageStorage = localStorage.getItem("language");
    i18next.changeLanguage(languageStorage ? languageStorage : "en");
    setSelectedLanguagesRedux(languageStorage ? languageStorage : "en");
  }, []);


  useEffect(() => {
    let fromdata = {
      page: 1,
      language: "en",
    };

    CoupanFetch(fromdata, (callbackData) => {
      setOffers(callbackData.data.data.offers);
    });
  }, []);


  useEffect(() => {
    if (getUserAuthenticationRedux) {
      fetchUserDetailsApi(getUserAuthenticationRedux, (data) => {
        setUserDetailRedux(data);
      });
      let languageStorage = localStorage.getItem("language");

      let formdata = {
        language: languageStorage,
      };
      FetchCartApi(getUserAuthenticationRedux, formdata, (callbackData) => {
        setCartLengthRedux(callbackData?.data?.cart_items?.length);
      });
      FetchWishlistApi(getUserAuthenticationRedux, formdata, (callbackData) => {
        setUpdateProductSetWishLength(callbackData);
      });
    }
  }, [getUserAuthenticationRedux, languageStorage]);

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.ctrlKey && event.key === 's') {
  //       event.preventDefault();
  //       alert("Screenshots are disabled!");
  //     }
  //     if (event.shiftKey && event.key === 'S') {
  //       event.preventDefault();
  //       alert("Screenshots are disabled!");
  //     }
  //   };

  //   window.addEventListener('keydown', handleKeyDown);

  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);

  return (
    <div>
      <Toaster />
      <Routes />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getUserAuthenticationRedux: state.userAuthentication.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedLanguagesRedux: (parameter) => {
      dispatch(setSelectedLanguages(parameter));
    },
    setUserAuthenticationRedux: (parameter) => {
      dispatch(setUserAuthentication(parameter));
    },
    setUserDetailRedux: (parameter) => {
      dispatch(setUserDetail(parameter));
    },
    setCartLengthRedux: (parameter) => {
      dispatch(setCartLength(parameter))
    },
    setUpdateProductSetWishLength: (parameter) => {
      dispatch(setWishLength(parameter))
    },
    setOffers: (parameter) => {
      dispatch(setOffersDetails(parameter))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
