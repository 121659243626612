import React, { useState, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../Styles/SidebarNavbar.css";
import { useNavigate } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import { LiaBorderAllSolid } from "react-icons/lia";
import { VscAccount } from "react-icons/vsc";
import { RiLockPasswordLine } from "react-icons/ri";
import { AiOutlineUserDelete } from "react-icons/ai";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { IoMdLogOut } from "react-icons/io";
import { MdOutlineLocalShipping } from "react-icons/md";
import { IoReturnDownForwardSharp } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa";
import { MdOutlineShoppingCart } from "react-icons/md";

function SidebarAccount({
  show,
  handleClose,
  setShow,
  handleOpen,
  handleOpen1,
  handleLogout
}) {
  const navigation = useNavigate();
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (show) {
      setIsAnimating(true);
    } else {
      const timer = setTimeout(() => {
        setIsAnimating(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [show]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        backdrop={true}
        scroll={true}
        className={`offcanvas ${isAnimating ? (show ? "offcanvas-enter" : "offcanvas-exit") : ""
          }`}
        style={{
          backgroundColor: "white",
          boxShadow: "0 1px 8px 0 rgba(0, 0, 0, 0.06)",
        }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <img
              onClick={() => navigation("/")}
              src="/img/logo2.jpg"
              alt="logo-img"
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <div
          style={{ borderBottom: "0.5px solid #807575" }}
          className="mx-3"
        ></div>
        <Offcanvas.Body style={{ padding: "0px" }}>
          <div className="px-3 sidebar-navbar">
            <ul className="sidebar-navbar-nav flex-column">
              <li
                className="sidebar-nav-item"
                onClick={() => {
                  navigation("/");
                  handleClose();
                }}
              >
                <p className="sidebar-nav-link d-flex align-items-center">
                  <IoHomeOutline
                    style={{
                      marginRight: "5px",
                      cursor: "pointer",
                    }}
                  />
                  Home
                </p>
              </li>
              <li
                className="sidebar-nav-item"
                onClick={() => {
                  setShow("myorders");
                  handleClose();
                }}
              >
                <p className="sidebar-nav-link d-flex align-items-center">
                  <LiaBorderAllSolid
                    style={{
                      marginRight: "5px",
                      cursor: "pointer",
                    }}
                  />
                  My Orders
                </p>
              </li>
              <li
                className="sidebar-nav-item"
                onClick={() => {
                  setShow("profile");
                  handleClose();
                }}
              >
                <p className="sidebar-nav-link d-flex align-items-center">
                  <VscAccount
                    style={{
                      marginRight: "5px",
                      cursor: "pointer",
                    }}
                  />
                  Profile
                </p>
              </li>
              <li
                className="sidebar-nav-item"
                onClick={() => {
                  handleOpen();
                  handleClose();
                }}
              >
                <p className="sidebar-nav-link d-flex align-items-center">
                  <RiLockPasswordLine
                    style={{
                      marginRight: "5px",
                      cursor: "pointer",
                    }}
                  />
                  Change Password
                </p>
              </li>
              <li
                className="sidebar-nav-item"
                onClick={() => {
                  handleOpen1();
                  handleClose();
                }}
              >
                <p className="sidebar-nav-link d-flex align-items-center">
                  <AiOutlineUserDelete
                    style={{
                      marginRight: "5px",
                      cursor: "pointer",
                    }}
                  />
                  Delete Account
                </p>
              </li>
              <li
                className="sidebar-nav-item"
                onClick={() => {
                  navigation("/wishlist");
                  handleClose();
                }}
              >
                <p className="sidebar-nav-link d-flex align-items-center">
                  <FaRegHeart 
                    style={{
                      marginRight: "5px",
                      cursor: "pointer",
                    }}
                  />
                  My Wishlist
                </p>
              </li>
              <li
                className="sidebar-nav-item"
                onClick={() => {
                  navigation("/cart");
                  handleClose();
                }}
              >
                <p className="sidebar-nav-link d-flex align-items-center">
                  <MdOutlineShoppingCart 
                    style={{
                      marginRight: "5px",
                      cursor: "pointer",
                    }}
                  />
                  My Cart
                </p>
              </li>
              {/* <li
                className="sidebar-nav-item"
                onClick={() => {
                  navigation("/terms-and-condition");
                  handleClose();
                }}
              >
                <p className="sidebar-nav-link d-flex align-items-center">
                  <IoBookOutline
                    style={{
                      marginRight: "5px",
                      cursor: "pointer",
                    }}
                  />
                  Terms and condition
                </p>
              </li> */}
              <li
                className="sidebar-nav-item"
                onClick={() => {
                  navigation("/privacy-policy");
                  handleClose();
                }}
              >
                <p className="sidebar-nav-link d-flex align-items-center">
                  <MdOutlinePrivacyTip
                    style={{
                      marginRight: "5px",
                      cursor: "pointer",
                    }}
                  />
                  Privacy Policy
                </p>
              </li>

              <li
                className="sidebar-nav-item"
                onClick={() => {
                  navigation("/shipping-policy");
                  handleClose();
                }}
              >
                <p className="sidebar-nav-link d-flex align-items-center">
                  <MdOutlineLocalShipping
                    style={{
                      marginRight: "5px",
                      cursor: "pointer",
                    }}
                  />
                  Shipping Policy
                </p>
              </li>

              <li
                className="sidebar-nav-item"
                onClick={() => {
                  navigation("/return-policy");
                  handleClose();
                }}
              >
                <p className="sidebar-nav-link d-flex align-items-center">
                  <IoReturnDownForwardSharp
                    style={{
                      marginRight: "5px",
                      cursor: "pointer",
                    }}
                  />
                  Return Policy
                </p>
              </li>


              <li
                className="sidebar-nav-item"
                onClick={() =>
                  handleLogout()
                }
              >
                <p className="sidebar-nav-link d-flex align-items-center">
                  <IoMdLogOut
                    style={{
                      marginRight: "5px",
                      cursor: "pointer",
                    }}
                  />
                  Logout
                </p>
              </li>
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default SidebarAccount;
