import React, { useEffect, useState } from 'react'
import Topbar from '../Sections/Topbar'
import Footer from '../Sections/Footer';
import Navbar from '../Sections/Navbar';
import "../Styles/AboutUs.css"

const AboutUs = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [])

  return (
    <>
      <div className={`top-bars ${isScrolled ? "scrolled" : ""}`}>
        <Topbar />
        <Navbar />
      </div>
      <div className='container about-us py-3'>
        <div className='row'>
          <div className='col-12'>
            <main>
              <section id="about">
                <h3 className='fw-bold'>About Us</h3>
                <p>HASASI DENIM WEAR is a premium denim jeans manufacturer based in Mumbai, India. Established in 2017, we have consistently delivered high-quality denim products with a keen eye for craftsmanship and design. Our passion for fashion, combined with an unwavering commitment to quality, has quickly positioned us as a recognized name in the industry. We cater to both men and women with a diverse range of denim styles, washes, fits, and finishes. From timeless straight-leg designs to contemporary skinny and distressed jeans, HASASI DENIM WEAR ensures there's a perfect pair for everyone. Every detail, from the stitching to the hardware, is crafted with care, creating jeans that are stylish, comfortable, and built to last.</p>
              </section>

              <section id="mission">
                <h6>Mission</h6>
                <p>Our mission is to provide denim that surpasses customer expectations in quality, comfort, and style. We aim to offer a memorable shopping experience, fostering long-term relationships with our customers built on trust and satisfaction.</p>
              </section>

              <section id="vision">
                <h6>Vision</h6>
                <p>We aspire to be the leading denim brand known for superior customer satisfaction, innovative designs, and premium quality. Our goal is to cultivate a loyal customer base by offering products that enhance personal style and deliver outstanding value.</p>
              </section>

              <section id="strengths">
                <h6>Our Strengths</h6>
                <p className='mb-0'>At HASASI DENIM WEAR, we take pride in:</p>
                <ul>
                  <li>Sourcing the finest raw materials for premium quality</li>
                  <li>Offering a wide range of denim options tailored to various preferences and body types</li>
                  <li>Ensuring meticulous craftsmanship in every pair of jeans</li>
                  <li>Maintaining competitive pricing while delivering high-quality products</li>
                  <li>Upholding a proven track record for on-time delivery of large orders with short lead times</li>
                  <li>Product Quality and Range</li>
                </ul>
              </section>

              <section id="product-quality">
                <p>We prioritize quality in every step of the manufacturing process, from fabric selection to final touches. Our denim collection features various styles, including classic, distressed, and clean-cut designs, catering to different tastes and body shapes. We continuously invest in research and development to stay ahead of trends, ensuring our customers always have access to the latest in denim fashion.</p>
              </section>

              <section id="denim-excellence">
                <h6>Denim Excellence for Men and Women</h6>
                <p>At HJ HASASI, we pride ourselves on offering denim that caters to diverse tastes and fashion
                  sensibilities. Our collection features a wide range of styles, from timeless Wide-leg jeans to modern
                  skinny fits, and everything in between. Whether you're looking for classic washes or contemporary
                  distressed designs, our jeans are crafted with meticulous attention to detail, ensuring you look great
                  while feeling comfortable.</p>
              </section>

              <section id="seamless-experience">
                <h6>Seamless Shopping Experience</h6>
                <p>HJ HASASI's commitment to making premium fashion accessible extends beyond marketplaces. With
                  our website, HJHASASI.com, we aim to provide a seamless online shopping experience, allowing you
                  to browse our extensive denim collection, select your preferred styles, and have them delivered right to
                  your doorstep, anywhere in India.
                </p>
              </section>

              <section id="quality-craftsmanship">
                <h6>Quality Craftsmanship</h6>
                <p>Our commitment to excellence is evident in every aspect of our products. Each pair of jeans is designed
                  with skilled craftsmanship, using high-quality materials that ensure longevity and style. Whether you're
                  shopping for everyday wear or a statement piece, HJ HASASI offers denim that enhances your personal
                  style and stands the test of time.
                </p>
              </section>

              <section id="customer-satisfaction">
                <h6>Customer Satisfaction</h6>
                <p>Customer satisfaction is at the core of our philosophy. We go the extra mile to ensure every interaction, from inquiry to delivery, is seamless. Our dedicated customer service team is always ready to assist, ensuring that orders are processed efficiently, and products are delivered on time.</p>
              </section>

              <section id="conclusion">
                <h6>Conclusion</h6>
                <p>At HASASI DENIM WEAR, we strive to create premium jeans that exude style, comfort, and durability. Our dedication to quality and customer service ensures we surpass customer expectations, fostering relationships built on trust and mutual growth.</p>
              </section>

              <section id="contact">
                <h6>Contact Us</h6>
                <address>
                  <p>HASASI DENIM WEAR<br></br>
                    9th Floor, Unit No. 914, Wing C & D, Shiv Solitaire, Station Road, Jogeshwari East,<br></br>
                    Mumbai - 400060</p>
                  <p>Mobile Number: <a href="tel:8779958981">8779958981</a><br></br>
                    Email Id: <a href="mailto:support@hasasidenim.com">support@hasasidenim.com</a></p>
                </address>
              </section>
            </main>
            <footer>
              <p>&copy; 2024 HASASI DENIM WEAR. All rights reserved.</p>
            </footer>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AboutUs