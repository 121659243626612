import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginApi } from "../APIS/AuthApis";
import { setUserAuthentication } from "../Redux/actions";
import { ErrorToaster } from "../Toaster";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { auth, provider } from "./Configer";
import { signInWithPopup } from "firebase/auth";
import { SocialLoginGoogle } from "../APIS/SocialApi";
import { authfacebook, fbAuthProvider } from "./Fibrebase";
import { useTranslation } from "react-i18next";
import {
  AiOutlineEyeInvisible,
  AiOutlineEye,
  AiOutlineGoogle,
} from "react-icons/ai";
import "./../Styles/login.css";

let emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
function Login({ setUserAuthenticationRedux }) {
  const navigation = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailError, setEmailrror] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    if (email === "") {
      setEmailrror("Please Enter The Email");
      ErrorToaster("Please Enter The Email");
    } else if (password === "") {
      setPasswordError("Please Enter The Password");
      ErrorToaster("Please Enter The Password");
    } else {
      loginApi(email, password, navigation, setUserAuthenticationRedux);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 d-none d-lg-flex d-none login-left p-0">
            <img src="\img\singupimage\signup.jpg" alt="image" />
          </div>

          <div className="col-lg-6 col-12  login-right d-flex justify-content-center ">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <img
                onClick={() => navigation("/")}
                src="/img/logo_main.png"
                alt="logo-img"
                width={"100px"}
                height={"100%"}
                style={{ cursor: "pointer" }}
              />
              <h3>{t("loginlogExclusive")}</h3>
              <p className="enter-details">{t("loginDetails")}</p>
              <form onSubmit={(e) => handleLogin(e)}>
                <input
                  style={{
                    borderColor: emailError ? "red" : "",
                    color: emailError ? "red" : "",
                  }}
                  type="text"
                  placeholder="Email"
                  className="mt-4 p-2"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailrror(false);
                  }}
                />
                {emailError ? (
                  <p className="m-0 text-danger">{emailError}</p>
                ) : null}

                <div className="d-flex align-items-center position-relative ">
                  <input
                    style={{
                      borderColor: passwordError ? "red" : "",
                      color: passwordError ? "red" : "",
                    }}
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    className="mt-4 p-2"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div>
                    {showPassword ? (
                      <AiOutlineEyeInvisible
                        onClick={() => setShowPassword(!showPassword)}
                        className="eye-icond"
                      />
                    ) : (
                      <AiOutlineEye
                        onClick={() => setShowPassword(!showPassword)}
                        className="eye-icond"
                      />
                    )}
                  </div>
                </div>
                {passwordError ? (
                  <p className="m-0 text-danger">{passwordError}</p>
                ) : null}

                <button
                  type="submit"
                  className="d-flex justify-content-center align-items-center w-100  mt-3 create-btn"
                  style={{ backgroundColor: email !== '' && password !== '' ? "#2e2e97" : "", color: email !== '' && password !== '' ? "#ffffff" : "" }}
                  disabled={!email && !password}
                >
                  {t("Login")}
                </button>
              </form>

              <div>
                <h6 className="text-center mt-3 login-already-account">
                  {t("DonAccount")}?{" "}
                  <span onClick={() => navigation("/signup")}>
                    {t("ClickHere")}
                  </span>
                </h6>
                <NavLink
                  to="/forget-password"
                  className="mt-1 text-decoration-none ms-sm-0 ms-2"
                  style={{ fontSize: "12px" }}
                >
                  <h6 className="text-center mt-3 login-already-account">
                    <span>
                      {t("loginForgetPassword")}
                    </span>
                  </h6>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserAuthenticationRedux: (parameter) => {
      dispatch(setUserAuthentication(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
