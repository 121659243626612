import React from 'react'
import Home from '../Home/Home'
import ProductPage from '../ProductPage/ProductPage';
import CartPage from '../Pages/CartPage';
import CategoryPage from '../ProductPage/CategoryPage';
import Login from '../Auth/Login';
import Signup from '../Auth/Signup';
import ProfilePage from '../Pages/ProfilePage';
import WishlistPage from '../Pages/WishlistPage';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import TermsonUse from '../Pages/TermsonUse';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import AddrerssItem from '../Pages/AddrerssItem';
import Contactuspages from '../Pages/Contactuspages';
import Forgetpassword from '../Auth/Forgetpassword';
import Resetpassword from '../Auth/Resetpassword';
import AboutUs from '../Pages/AboutUs';
import ShippingPolicy from '../Pages/ShippingPolicy';
import ReturnPolicy from '../Pages/ReturnPolicy';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: '/:productSlug',
    element: <ProductPage />

  },
  {
    path: '/cart',
    element: <CartPage />

  },
  {
    path: '/category/:categorySlug',
    element: <CategoryPage />

  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/signup',
    element: <Signup />
  },
  {
    path: '/profile',
    element: <ProfilePage />
  },
  {
    path: '/wishlist',
    element: <WishlistPage />
  },
  {
    path: '/terms-and-condition',
    element: <TermsonUse />
  },
  {
    path: '/shipping-policy',
    element: <ShippingPolicy />
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />
  },
  {
    path: '/return-policy',
    element: <ReturnPolicy />
  },
  {
    path: '/checkout/payment',
    element: <AddrerssItem />
  },
  {
    path: "/contact", element: <Contactuspages />
  },
  {
    path: "/forget-password", element: <Forgetpassword />
  },
  {
    path: "/Reset-password", element: <Resetpassword />
  },
  { path: "/about-us", element: <AboutUs /> },

]);

function Routes() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  )
}

export default Routes
