import React, { useLayoutEffect, useState, useEffect } from "react";
import { AiOutlinePlus, AiTwotoneDelete } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import Footer from "../Sections/Footer";
import Topbar from "../Sections/Topbar";
import Navbar from "../Sections/Navbar";
import {
  FetchCartApi,
  AddToCartApi,
  UpdateCartApi,
  deleteCartApi,
} from "../APIS/cartApis";
import { FetchWishlistApi, RemoveWishlistApi } from "../APIS/WishlistApis";
import { connect } from "react-redux";
import { setWishLength } from "../Redux/actions";
import "../Styles/cartpage.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Banner from "../Sections/Banner";
import Spinner from "react-bootstrap/Spinner";
import CurrencyFormat from "../Utils/CurrencyFormat";

function WishlistPage({ getSelectedLanguage, setUpdateProductSetWishLength }) {
  const [allWishtDate, setAllWishData] = useState([]);
  const [updateWishtPage, setUpdateWishPage] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [updateProductGetWishLength, setUpdateProductGetWishLength] =
    useState(false);
  const [updateProductGetCartLength, setUpdateProductGetCartLength] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleDeleteWish = (cartId) => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      productId: cartId,
      language: getSelectedLanguage,
    };
    RemoveWishlistApi(
      Relove_Token,
      formdata,
      updateWishtPage,
      setUpdateWishPage
    );
  };

  useLayoutEffect(() => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      language: getSelectedLanguage,
    };
    FetchWishlistApi(Relove_Token, formdata, setLoading, (callbackData) => {
      setAllWishData(callbackData);
      setUpdateProductSetWishLength(callbackData);
    });
  }, [updateWishtPage]);

  const navigation = useNavigate();
  const handleVeiw = (id, slug) => {
    navigation(`/${slug}_${id}`);
  };
  return (
    <>
      <div className={`top-bars ${isScrolled ? "scrolled" : ""}`}>
        <Topbar />
        <Navbar />
      </div>
      {/* <Banner bannerImage="/img/wishlistbanner.jpg" /> */}
      {loading ? (
        <div className="product_page_loader">
          <Spinner animation="border" role="status"></Spinner>
        </div>
      ) : (
        <>
          <div className="container">
            <div className="row">
              <div className="col-12 p-lg-0">
                <h3 className="mt-3 mb-0">Wishlist</h3>
              </div>
            </div>
          </div>
          <div className="container-fluid  main-cart">
            <div className="container p-0">
              <div className="row header my-4 d-sm-flex wishlist-header">
                <div className="col-4">
                  <h5 className="text-center">{t("ProducetCart")}</h5>
                </div>
                <div className="col-2">
                  <h5 className="text-center">{t("PriceCart")}</h5>
                </div>
                <div className="col-2">
                  <h5 className="text-center">{t("ViewItem")}</h5>
                </div>
                <div className="col-2">
                  <h5 className="text-center">{t("RemoveCart")}</h5>
                </div>
              </div>

              <div className="container p-0" style={{ minHeight: "500px" }}>
                {allWishtDate?.data?.length === 0 ? (
                  <div className="my-5 d-flex justify-content-center align-items-center Emtaycart">
                    <h1>{t("EmptyWishlist")}</h1>
                  </div>
                ) : (
                  allWishtDate?.data?.map((item, i) => {
                    return (
                      <>
                        <div className="row mt-4 mb-4 shadow-sm h-25 d-flex justify-content-between cart-desktop">
                          <div className="col-sm-4 col-12 p-3 d-flex justify-content-between align-content-center left">
                            <div className="row  d-flex align-items-center">
                              <div className=" col-sm-6 col-12">
                                <div className="left-men-image d-flex justify-content-center">
                                  <img
                                    src={item?.featured_image}
                                    alt="images"
                                    className="wishlist-image"
                                    onClick={() => handleVeiw(item.product_id, item.slug)}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6 col-12">
                                <div className="left-men-image-text d-flex flex-column justify-content-center">
                                  {/* <h5 className="mt-1 p-0 men-kurta">
                                {t('MenCart')}
                              </h5> */}
                                  <p
                                    className=""
                                    style={{
                                      display: "-webkit-box",
                                      WebkitBoxOrient: "vertical",
                                      WebkitLineClamp: 3,
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "normal",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {item?.name}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-2 p-0 d-flex justify-content-sm-center align-items-center left-center">
                            <div
                              className="d-flex align-items-center"
                              id="handlecart"
                            >
                              <span className="d-sm-none handle-sm-span">
                                {t("Pricesmallcart")}
                              </span>
                              <span className="d-sm-none fs-bold ms-5">:</span>
                              <h6 className="m-0 ₹">
                                <CurrencyFormat amount={item?.sale_price} />
                                {/* {t("₹")} {item?.sale_price} */}
                              </h6>
                            </div>
                          </div>
                          <div className="col-sm-2 d-flex justify-content-sm-around align-items-center center ms-0">
                            <div
                              className="d-flex align-items-center"
                              id="handlecart"
                            >
                              <span className="d-sm-none handle-sm-span">
                                {t("ViewWhishlist")}
                              </span>
                              <span className="d-sm-none fs-bold ms-5">:</span>
                              <button
                                className="d-flex justify-content-around w-75  center-number align-items-center"
                                style={{ border: "none", minWidth: "100px" }}
                                onClick={() => handleVeiw(item.product_id, item.slug)}
                              >
                                {t("ViewItem")}
                              </button>
                            </div>
                          </div>
                          <div className="col-sm-2 delete_cart d-flex justify-content-sm-center align-items-center right-center">
                            <div
                              className="d-flex align-items-center"
                              id="handlecart"
                            >
                              <span className="d-sm-none handle-sm-span">
                                {t("Removesmallcart")}
                              </span>
                              <span className="d-sm-none fs-bold ms-3">:</span>
                              <h6 className="m-0 free ms-2">
                                <AiTwotoneDelete
                                  onClick={() =>
                                    handleDeleteWish(item.product_id)
                                  }
                                />
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="cart-mobile">
                          <div className="row shadow-mobile" key={i}>
                            <div className="col-4 d-flex justify-content-center align-items-start">
                              <img
                                src={item?.featured_image}
                                alt="images"
                                className="men-image"
                                onClick={() => handleVeiw(item.product_id)}
                              />
                            </div>
                            <div className="col-8 d-flex flex-column justify-content-center">
                              <p
                                className="cart-mobile-name mb-2"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {" "}
                                {item?.name}
                              </p>
                              <p className="cart-mobile-price mb-2">
                                <CurrencyFormat amount={item?.sale_price} />
                                {/* {t("₹")} {item?.sale_price} */}
                              </p>
                              <div className="d-flex align-items-center">
                                <button
                                  className="d-flex justify-content-around center-number align-items-center"
                                  style={{ border: "none", minWidth: "100px" }}
                                  onClick={() => handleVeiw(item.product_id)}
                                >
                                  {t("ViewItem")}
                                </button>
                                <h6 className="m-0 free ms-2">
                                  <AiTwotoneDelete
                                    onClick={() =>
                                      handleDeleteWish(item.product_id)
                                    }
                                  />
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getSelectedLanguage: state.language.language,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUpdateProductSetWishLength: (parameter) => {
      dispatch(setWishLength(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WishlistPage);
