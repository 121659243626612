import React, { useEffect, useLayoutEffect, useState } from "react";
import { AiOutlinePlus, AiTwotoneDelete } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import Footer from "../Sections/Footer";
import Topbar from "../Sections/Topbar";
import Navbar from "../Sections/Navbar";
import { FetchCartApi, UpdateCartApi, deleteCartApi } from "../APIS/cartApis";
import { CoupanFetch, CoupanApply, CoupanRemove } from "../APIS/CoupanApi";
import { connect } from "react-redux";
import { setCartLength } from "../Redux/actions";
import "../Styles/cartpage.css";
import { SuccessToaster, ErrorToaster } from "../Toaster";
import { FaCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Banner from "../Sections/Banner";
import Spinner from "react-bootstrap/Spinner";
import CurrencyFormat from "../Utils/CurrencyFormat";

function CartPage({ getSelectedLanguage, setCartLengthRedux }) {
  const navigation = useNavigate();
  const { t } = useTranslation();

  const [allCartDate, setAllCartData] = useState([]);
  const [updateCartPage, setUpdateCartPage] = useState(false);
  const [inputCoupan, setInputCoupan] = useState({
    coupan: "",
  });
  const [outputCoupan, setOutputCoupan] = useState([]);
  const [coupon, setCoupon] = useState(false);
  const [Removecode, setRemovecode] = useState("");
  const [Removeshow, setRemoveshow] = useState("");
  const [RemoveId, setRemoveId] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [])

  useEffect(() => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      language: getSelectedLanguage,
    };
    FetchCartApi(Relove_Token, formdata, setLoading, (callbackData) => {
      setAllCartData(callbackData);
      setCartLengthRedux(callbackData?.data?.cart_items?.length);
    });
  }, [updateCartPage, getSelectedLanguage]);

  const updateCartHandle = (cartId, productId, variationId, quantity) => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      cartId: cartId,
      productId: productId,
      variationId: variationId,
      quantity: quantity,
      language: getSelectedLanguage,
    };
    UpdateCartApi(Relove_Token, formdata, updateCartPage, setUpdateCartPage);
  };

  const handleDeleteCart = (cartId) => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      cartId: cartId,
      language: getSelectedLanguage,
    };
    deleteCartApi(Relove_Token, formdata, updateCartPage, setUpdateCartPage);
  };

  useEffect(() => {
    let fromdata = {
      page: 1,
      language: getSelectedLanguage,
    };

    CoupanFetch(fromdata, (callbackData) => {
      setOutputCoupan(callbackData.data.data.offers);
    });
  }, [updateCartPage, getSelectedLanguage]);

  const applyCoupon = () => {
    let result = "";
    console.log(result);
    let Relove_Token = localStorage.getItem("Relove_Token");
    outputCoupan.forEach((element, i) => {
      if (element.name.toLowerCase() === inputCoupan.coupan.toLowerCase()) {
        result = element.id;

        let fromdata = {
          couponId: result,
        };
        CoupanApply(fromdata, Relove_Token, setUpdateCartPage, updateCartPage);
        setRemovecode(element.name);
        setRemoveId(element.id);
        setRemoveshow(true);
      }
    });
    if (result === "") {
      ErrorToaster("Apply coupon failed");
    }
  };

  const handleCoupan = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputCoupan({ ...inputCoupan, [name]: value });
  };

  const handleRemove = () => {
    let Relove_Token = localStorage.getItem("Relove_Token");

    let fromdata = {
      removeId: allCartDate?.couponInfo[0]?.discountId,
    };

    CoupanRemove(fromdata, Relove_Token, setUpdateCartPage, updateCartPage);
    setRemoveshow(false);
  };

  return (
    <>
      <div className={`top-bars ${isScrolled ? "scrolled" : ""}`}>
        <Topbar />
        <Navbar />
      </div>
      {
        loading ? <div className="product_page_loader">
          <Spinner animation="border" role="status">
          </Spinner>
        </div> :
          <>
            <div className="container">
              <div className="row">
                <div className="col-12 p-lg-0">
                  <h3 className="mt-3 mb-0">Shopping Cart</h3>
                </div>
              </div>
            </div>


            <div className="container-fluid  main-cart">
              <div className="container p-0 p-sm-2">
                <div className="row header mt-4">
                  <div className="col-4">
                    <h5 className="text-center">{t('ProducetCart')}</h5>
                  </div>

                  <div className="col-2">
                    <h5 className="text-center">{t('PriceCart')}</h5>
                  </div>
                  <div className="col-2">
                    <h5 className="text-center">{t('QuantityCart')}</h5>
                  </div>
                  <div className="col-2">
                    <h5 className="text-center">{t('RemoveCart')}</h5>
                  </div>
                  <div className="col-2">
                    <h5 className="text-center">{t('SubTotalCart')}</h5>
                  </div>
                </div>

                <div className="container p-0">
                  {allCartDate?.data?.cart_items.length === 0 ? (

                    <div className="mt-5 d-flex justify-content-center align-items-center Emtaycart">
                      <h1>{t('EmptyCart')}</h1>
                    </div>
                  ) : allCartDate?.data?.cart_items?.map((item, i) => {
                    return (
                      <>
                        <div
                          key={i}
                          className="row mt-4 shadow-sm d-flex justify-content-center align-items-center cart-desktop"
                        >
                          <div className="col-md-4 col-sm-12 p-3 left">
                            <div className="row d-flex align-items-center ">
                              <div className="col-md-6 col-12">
                                <div className="left-men-image d-flex justify-content-center">

                                  <img
                                    src={item?.productDetails[0]?.imagesGet.length ? item?.productDetails[0]?.productsVariationsImages[0] : item?.productDetails[0]?.featured_image}
                                    alt="images"
                                    className="men-image"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-12">
                                <div className="left-men-image-text d-flex flex-column justify-content-center">
                                  <p className="cart-mobile-name mb-1" style={{
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 2,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'normal'
                                  }}> {item?.variationData?.variation_name}</p>
                                  <p className="cart-mobile-variation-name mb-1 d-flex align-items-center">{item?.variationData?.variations[0].attributeName}{" "}<p className="m-0"> :</p> <p className="m-0 ms-2" style={{ fontWeight: "500" }}>{item?.variationData?.variations[0].variationName}</p></p>
                                  <p className="cart-mobile-variation-name mb-1 d-flex align-items-center">{item?.variationData?.variations[1].attributeName}{" "}<p className="m-0"> :</p> <p className="m-0 ms-2" style={{ fontWeight: "500" }}>{item?.variationData?.variations[1].variationName}</p></p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2   col-sm-12 p-0 d-flex justify-content-md-center left-center">
                            <div className="d-flex align-items-center" id="handlecart"><span className="d-md-none handle-sm-span">{t('Pricesmallcart')}</span>
                              <span className="d-md-none fs-bold ms-5">:</span>
                              <h6 className="m-0 ₹">
                                <CurrencyFormat amount={item?.variationData?.sale_price} />
                                {/* {t('₹')} {item?.variationData?.sale_price} */}
                              </h6>
                            </div>
                          </div>
                          <div className="col-md-2 col-sm-12 d-flex justify-content-md-center center">
                            <div className="d-flex align-items-center" id="handlecart"><span className="d-md-none handle-sm-span">{t('Quantitysmallcart')}</span>
                              <span className="d-md-none fs-bold ms-3">:</span>

                              <div className="d-flex justify-content-around  center-number align-items-center ">
                                <AiOutlineMinus
                                  className="pointer"
                                  onClick={() =>
                                    updateCartHandle(
                                      item.cartId,
                                      item?.productDetails[0]?.id,
                                      item?.variationId,
                                      parseInt(item?.quantity) - 1
                                    )
                                  }
                                />
                                <p className="m-0">{item?.quantity}</p>
                                <AiOutlinePlus
                                  className="pointer"
                                  onClick={() =>
                                    updateCartHandle(
                                      item.cartId,
                                      item?.productDetails[0]?.id,
                                      item?.variationId,
                                      parseInt(item?.quantity) + 1
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 col-sm-12 d-flex justify-content-md-center delete_cart  right-center">
                            <div className="d-flex align-items-center" id="handlecart"><span className="d-md-none handle-sm-span">{t('Removesmallcart')}</span>
                              <span className="d-md-none fs-bold ms-3">:</span>

                              <h6 className="m-0 free ms-md-0 ms-3">
                                <AiTwotoneDelete
                                  onClick={() => handleDeleteCart(item.cartId)}
                                />
                              </h6>
                            </div>
                          </div>
                          <div className="col-md-2 col-sm-12 p-0 d-flex justify-content-md-center right">
                            <div className="d-flex align-items-center" id="handlecart"><span className="d-md-none handle-sm-span">{t('Subtotalsmallcart')}</span>
                              <span className="d-md-none fs-bold ms-2">:</span>

                              <h6 className="m-0 ₹">
                                <CurrencyFormat amount={item?.variationData?.sale_price * item?.quantity} />
                                {/* {t('₹')} {item?.variationData?.sale_price * item?.quantity} */}
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="cart-mobile">
                          <div className="row shadow-mobile" key={i}>
                            <div className="col-4 d-flex justify-content-center align-items-start">
                              <img
                                src={item?.productDetails[0]?.imagesGet.length ? item?.productDetails[0]?.productsVariationsImages[0] : item?.productDetails[0]?.featured_image}
                                alt="images"
                                className="men-image"
                              />
                            </div>
                            <div className="col-8">
                              <p className="cart-mobile-name mb-1" style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}> {item?.productDetails[0]?.name}</p>
                              <p className="cart-mobile-variation-name mb-1 d-flex align-items-center">{item?.variationData?.variations[0].attributeName} : <p className="m-0 ms-2" style={{ fontWeight: "500" }}>{item?.variationData?.variations[0].variationName}</p></p>
                              <p className="cart-mobile-variation-name mb-1 d-flex align-items-center">{item?.variationData?.variations[1].attributeName} : <p className="m-0 ms-2" style={{ fontWeight: "500" }}>{item?.variationData?.variations[1].variationName}</p></p>
                              <p className="cart-mobile-price mb-1"><CurrencyFormat amount={item?.variationData?.sale_price} /></p>
                              <div className="d-flex align-items-center mb-1">
                                <div className="d-flex justify-content-around  center-number align-items-center m-0 me-3">
                                  <AiOutlineMinus
                                    className="pointer"
                                    onClick={() =>
                                      updateCartHandle(
                                        item.cartId,
                                        item?.productDetails[0]?.id,
                                        item?.variationId,
                                        parseInt(item?.quantity) - 1
                                      )
                                    }
                                  />
                                  <p className="m-0">{item?.quantity}</p>
                                  <AiOutlinePlus
                                    className="pointer"
                                    onClick={() =>
                                      updateCartHandle(
                                        item.cartId,
                                        item?.productDetails[0]?.id,
                                        item?.variationId,
                                        parseInt(item?.quantity) + 1
                                      )
                                    }
                                  />
                                </div>
                                <h6 className="m-0 ml-sm-3 free ms-md-0 ms-3">
                                  <AiTwotoneDelete
                                    onClick={() => handleDeleteCart(item.cartId)}
                                  />
                                </h6>
                              </div>
                              <p className="mb-0">
                                Total Price :   <CurrencyFormat amount={item?.variationData?.sale_price * item?.quantity} />
                                {/* {t('₹')} {item?.variationData?.sale_price * item?.quantity} */}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>

                <div className="button d-flex justify-content-between align-items-center mt-4">
                  <button className="d-flex justify-content-center align-items-center return-btn" onClick={() => navigation(-1)}>
                    {t('ReturnCartshop')}
                  </button>

                </div>

                <div className="row my-5">
                  <div className="col-sm-5"></div>
                  {/* <div className="col-sm-5">
              <div className=" d-flex justify-content-start ">
                <input
                  type="text"
                  placeholder={t('CouponCode')}
                  className="cupon-btn"
                  value={inputCoupan.coupan}
                  name="coupan"
                  onChange={handleCoupan}
                />
                <button
                  className="apply-btn d-flex justify-content-center align-items-center mx-3"
                  onClick={() => {
                    if (allCartDate?.data?.length !== 0) { applyCoupon() }
                    else {
                      ErrorToaster("Your Cart is Empty")
                    }
                  }}
                >
                  {t('ApplyCoupanCart')}
                </button>
              </div>
              {Removeshow === true ? (
                <div className="d-flex my-5 align-itams-center ">
                  <div
                    className="remove-card"
                    style={{
                      height: "auto",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <p className="" style={{ color: "green" }}>
                      <FaCheck />
                      <span className="ms-3">{Removecode}</span>
                    </p>
                  </div>
                  <button
                    className="bg-danger ms-3 text-white remove-coupun-button"
                    onClick={handleRemove}
                  >
                    {" "}
                    {t('RemoveCoupanCart')}
                  </button>
                </div>
              ) : (
                ""
              )}
            </div> */}
                  <div className="col-sm-1"></div>

                  {allCartDate?.data?.couponInfo?.map((item, i) => {
                    return (
                      <div key={i} className="col-md-6 bottom-card">
                        <h4>{t('CartTotalInfoCart')}</h4>
                        <div className=" d-flex justify-content-between align-items-center sub-total mt-4">
                          <p>{t('SubTotalInfoCart')}</p>
                          <p>  <CurrencyFormat amount={item?.subtotalAmount} /></p>
                        </div>
                        <div className=" d-flex justify-content-center align-item-center mt-2 mb-2">
                          <button
                            className="d-flex justify-content-center align-item-center process-to-checkout-btn"
                            onClick={() => {
                              if (allCartDate?.data?.length !== 0) {
                                navigation("/checkout/payment")
                                window.scroll(0, 0)
                              }

                              else {
                                ErrorToaster("Your Cart is Empty")
                              }
                            }}
                          >
                            {t('CheckOutInfo')}
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div></>
      }
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getSelectedLanguage: state.language.language,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCartLengthRedux: (parameter) => {
      dispatch(setCartLength(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartPage);
