import React, { useEffect, useState } from 'react'
import Topbar from '../Sections/Topbar'
import Footer from '../Sections/Footer';
import Navbar from '../Sections/Navbar';
import "../Styles/ReturnPolicy.css"

const ReturnPolicy = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [])

    return (
        <>
            <div className={`top-bars ${isScrolled ? "scrolled" : ""}`}>
                <Topbar />
                <Navbar />
            </div>
            <div className='container py-3 return-policy'>
                <div className='row'>
                    <div className='col-12'>
                        <h3 className='fw-bold'>Return & Exchange Policy</h3>
                        <p>Effective as of September 2024</p>
                        <p>Note: HASASI DENIM WEAR (hereafter referred to as “HDW”)</p>
                        <p>At HDW, we prioritize offering a seamless shopping experience. Our return and exchange policy ensures that all concerns are handled efficiently. Please review the guidelines below to understand our procedures.</p>

                        <h6>Return & Exchange Period:</h6>
                        <ul>
                            <li>We offer a 72-hour window for hassle-free exchanges and returns from the date of delivery.</li>
                            <li>If provided your PIN code is serviceable. Pick-up will be attempted twice. If unsuccessful, you will need to send the shipment back to our company address. Reverse pick-up availability depends on serviceable PIN codes.</li>
                            <li>Ensure the returned product is in the same condition as it was shipped. If the item shows signs of usage or is returned in poor condition, an exchange or refund will not be processed.</li>
                            <li>Returns must reach us within 10-15 days of initiating the request.</li>
                        </ul>

                        <h6>Refunds for Prepaid Orders:</h6>
                        <ul>
                            <li>Refunds for prepaid orders will be processed via bank transfer within 7 days after the product passes our quality check.</li>
                            <li>Shipping charges are non-refundable for both COD and prepaid orders.</li>
                            <li>Once the product passes the quality check, bank transfers will be processed via IMPS or NEFT, and the transaction details will be shared within 48 hours of processing.</li>
                            <li>Bank Account Details: The bank account details provided by the customer will be considered final. Any errors in these details will not be the responsibility of HDW.</li>
                        </ul>

                        <h6>Refunds for COD Orders:</h6>
                        <ul>
                            <li>For COD orders, Customer will be allowed to choose whether he/she wants coupe or want refund in their bank account if they want refund in their bank account then they will be allowed enter bank account details on the website. And the refund will be processed in next 7 days after the product passes our quality check.</li>
                            <li>Shipping charges are non-refundable for both COD and prepaid orders.</li>
                            <li>The coupon code will be valid for 1 year and can be used in a single transaction or the customer can take a refund in their bank account by providing the bank account details on the website.</li>
                            <li>Bank Account Details: The bank account details provided by the customer will be considered final. Any errors in these details will not be the responsibility of HDW.</li>
                        </ul>

                        <h6>Return Process:</h6>
                        <ol>
                            <li>Initiate a Return Request: Visit our website and fill out the return request form.</li>
                            <li>Upload an Image: Provide an original image of the product with tags intact (website screenshots are invalid).</li>
                            <li>Return Pick-Up: Our team will arrange for a return pick-up. If your PIN code is non-serviceable, you will need to self-ship the product to our warehouse.</li>
                            <li>Quality Check: After receiving the product, we will conduct a quality check. If the product passes the check, a coupon code will be issued. No coupon will be provided for damaged products caused by the user.</li>
                            <li>Once the product passes the quality check, the customer will be allowed to share his/her bank account details on the website. Bank transfers will be processed via IMPS or NEFT, and the transaction details will be shared within 48 hours of processing.</li>
                        </ol>

                        <h6>Return Pick-Up Fee:</h6>
                        <ul>
                            <li>A shipping fee will be charged for return pick-ups and deducted from the coupon code value or if the customer wants their money back to their bank account refund will be processed after deductions.</li>
                            <li>For multiple-item orders, the charge for return pick-ups applies per item.</li>
                            <li>No fees apply for wrong or damaged products.</li>
                        </ul>

                        <h6>Important Notes:</h6>
                        <ul>
                            <li>Missing Items: An unedited video of the parcel being opened is required to report missing items.</li>
                            <li>No Returns or Exchanges for Flash Sale Offer: Products purchased during the flash sale period are non-returnable and non-refundable unless the customer has received damaged or missing items in the packet and the issue is reported within the return window.</li>
                        </ul>

                        <h6>Contact Us:</h6>
                        <p>For any queries or issues related to returns, reach us at:</p>
                        <ul>
                            <li>Email: <a href="mailto:support@hasasidenim.com">support@hasasidenim.com</a></li>
                            <li>Instagram: <a href="https://instagram.com/hj_hasasi">@hj_hasasi</a></li>
                            <li>WhatsApp: <a href="tel:8779958981">8779958981</a> (Monday-Friday, 10 AM - 6 PM)</li>
                        </ul>

                        <h6>Self-Shipping for Returns:</h6>
                        <p>If reverse pick-up is unavailable, please self-ship the product to the following address:</p>
                        <p>HASASI DENIM WEAR<br></br>
                            9th Floor, Unit No. 914, Wing C & D,<br></br>
                            Shiv Solitaire, Jogeshwari Station Road,<br></br>
                            Jogeshwari East, Mumbai - 400060<br></br>
                            Mobile Number: <a href="tel:8779958981">8779958981</a></p>
                        <p>Ensure that items are securely packed and include your ORDER ID and registered mobile number. Items must be unused, with all tags and packaging intact.</p>

                        <h6>Refunds for Self-Shipped Orders:</h6>
                        <ul>
                            <li>For prepaid orders, refunds will be processed via bank transfer within 7 days after the product passes quality check.</li>
                            <li>Bank Account Details: The bank account details provided by the customer will be considered final. Any errors in these details will not be the responsibility of HDW.</li>
                            <li>Shipping charges are non-refundable.</li>
                            <li>COD orders will receive a coupon code equivalent to the order value (after deductions).</li>
                        </ul>

                        <h6>Recommendation:</h6>
                        <p>We recommend using Speed Post for self-shipping, as it is a reliable service owned by the Government of India.</p>

                        <h6>Terms and Conditions:</h6>
                        <ul>
                            <li>Bank Account Details: The bank account details provided by the customer will be considered final. Any errors in these details will not be the responsibility of HDW.</li>
                            <li>Order Delivery Notifications: If you receive an SMS or email stating that your order has been delivered but you haven't received it, please contact us within 24 hours via email at support@hasasidenim.com or by calling us.</li>
                            <li>Product Care: The customer is responsible for taking care of the product(s) while in their possession. This includes keeping all instructions, documents, and packaging intact. Products must be returned in the same condition as received.</li>
                            <li>Reporting Product Issues: If you believe the product does not meet the expected standards upon delivery, please contact us within 1 day via email with product details and images. We will provide further instructions.</li>
                            <li>Refund Process: Refunds will be processed within 7 working days from the date of product passes the quality check.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />

        </>
    )
}

export default ReturnPolicy
