import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ErrorToaster } from "../Toaster";
import { useTranslation } from "react-i18next";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { ResetverifyApi } from "../APIS/AuthApis";

const Resetpassword = () => {
  const { t } = useTranslation();

  const location = useLocation();
  // const reset_token = location?.state?.token;
  //  console.log(reset_token,"dfghjkl")
  const [newpassword, setNewpassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigation = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    let reset_token = localStorage.getItem("resetToken");
    let formdata = {
      password_reset_token: reset_token,
      newPassword: newpassword,
      confirmPassword: confirmpassword,
    };
    if (newpassword === "" || confirmpassword === "") {
      ErrorToaster("Enter your passwords");
    } else if (newpassword !== confirmpassword) {
      ErrorToaster("new password and confirm password must be same");
    } else {
      ResetverifyApi(formdata, navigation);
    }
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 d-none d-lg-flex col-12  login-left">
          <img src="\img\singupimage\signup.jpg" alt="image" />
        </div>
        <div className="col-lg-6 col-12  d-flex justify-content-center align-items-center resetpassword-right">
          <div className="w-100 px-sm-5 mt-sm-none mt-5">
            <h5 className="mb-3 fw-bold">{t("ResetPassword")}</h5>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label">{t("NewPassword")}</label>
                <div className="" style={{ position: "relative" }}>
                  <input
                    type={showPasswordNew ? "text" : "password"}
                    className="form-control"
                    name="newpassword"
                    value={newpassword}
                    onChange={(e) => setNewpassword(e.target.value)}
                  />
                  <div
                    style={{ position: "absolute", top: "10px", right: "20px" }}
                  >
                    {showPasswordNew ? (
                      <AiFillEye
                        onClick={() => setShowPasswordNew(!showPasswordNew)}
                      />
                    ) : (
                      <AiFillEyeInvisible
                        onClick={() => setShowPasswordNew(!showPasswordNew)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">{t("ConfirmPassword")}</label>
                <div className="" style={{ position: "relative" }}>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    name="confirmpassword"
                    value={confirmpassword}
                    onChange={(e) => setConfirmpassword(e.target.value)}
                  />
                  <div
                    style={{ position: "absolute", top: "10px", right: "20px" }}
                  >
                    {showPassword ? (
                      <AiFillEye
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <AiFillEyeInvisible
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <button
                  type="submit"
                  className="btn btn-success w-100"
                  style={{
                    backgroundColor: "#2e2e97",
                    border: "#2e2e97",
                    color: "white",
                  }}
                >
                  {t("UpdatePassword")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resetpassword;
