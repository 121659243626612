import React, { useEffect, useState } from 'react'
import Topbar from '../Sections/Topbar'
import Footer from '../Sections/Footer';
import Navbar from '../Sections/Navbar';
import "../Styles/ShippingPolicy.css"

const ShippingPolicy = () => {

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [])

    return (
        <>
            <div className={`top-bars ${isScrolled ? "scrolled" : ""}`}>
                <Topbar />
                <Navbar />
            </div>
            <div className='shipping-policy py-3'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <header>
                                <h3 className='fw-bold'>Shipping Policy</h3>
                                <p>Updated as of September, 2024</p>
                            </header>
                            <main>
                                <section>
                                    <h6>Thank you for shopping with HJ HASASI!</h6>
                                    <p>We strive to make your shopping experience smooth, especially when it comes to delivering your favorite apparel. Please review our shipping policy below:</p>
                                </section>
                                <section>
                                    <h6>1. Order Dispatch</h6>
                                    <p>All orders are dispatched within 24 hours of being placed. (Dispatch times may vary during big sale events or special promotions.)</p>
                                </section>
                                <section>
                                    <h6>2. Delivery Timeline</h6>
                                    <p>Orders are typically delivered within 7 to 8 working days from the dispatch date. Delivery times may vary for areas with limited connectivity.</p>
                                </section>
                                <section>
                                    <h6>3. Shipping Charges</h6>
                                    <p>Shipping charges depend on the product(s) purchased, the applied coupons, or the cart value.</p>
                                </section>
                                <section>
                                    <h6>4. Shipment Tracking</h6>
                                    <p>Customers will receive shipping details, including tracking information, via the registered contact details provided at the time of order placement.</p>
                                </section>
                                <section>
                                    <h6>5. Order Shipping</h6>
                                    <p>Orders are shipped from any of our warehouses. Due to the popularity of our offers, please allow 3 to 7 working days for delivery.</p>
                                </section>
                                <section>
                                    <h6>6. Lost or Delayed Shipments</h6>
                                    <p>Delivery delays may occur due to unforeseen circumstances such as weather conditions or political issues in the delivery zone.</p>
                                    <p>In the rare event of a lost or significantly delayed shipment, please contact us at <a href="mailto:support@hasasidenim.com">support@hasasidenim.com</a>, and we will work promptly to resolve the issue.</p>
                                </section>
                                <section>
                                    <h6>7. Shipping Partners</h6>
                                    <p>We partner with reputable courier services to ensure safe and timely delivery of your orders.</p>
                                </section>
                                <section>
                                    <h6>8. Delivery Address</h6>
                                    <p>Please ensure the delivery address provided at checkout is accurate. We are not responsible for delays or non-delivery due to incorrect address details.</p>
                                    <p>A change of address is possible only if the order has not yet been dispatched. Once the order is in transit, address changes cannot be made.</p>
                                </section>
                                <footer>
                                    <h6>For Further Assistance</h6>
                                    <p>Contact our customer support at <a href="mailto:support@hasasidenim.com">support@hasasidenim.com</a> or Mobile Number +91 87799 58981 (WhatsApp Only).</p>
                                </footer>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ShippingPolicy
