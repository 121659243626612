import React, { useState, useEffect } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import "../Styles/SidebarNavbar.css"
import { useNavigate } from "react-router-dom";
import { BiCategory } from "react-icons/bi";
import { IoHomeOutline } from "react-icons/io5";
import { SlUser } from "react-icons/sl";
import { CiHeart } from "react-icons/ci";
import { CiShoppingCart } from "react-icons/ci";

function SidebarNavbar({ show, handleClose, data, userId, setShowLoginModal, getWishLengthRedux, getCartLengthRedux }) {
    const navigation = useNavigate()
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        if (show) {
            setIsAnimating(true);
        } else {
            const timer = setTimeout(() => {
                setIsAnimating(false);
            }, 300);
            return () => clearTimeout(timer);
        }
    }, [show]);

    return (
        <>
            <Offcanvas
                show={show}
                onHide={handleClose}
                backdrop={true}
                scroll={true}
                className={`offcanvas ${isAnimating ? (show ? 'offcanvas-enter' : 'offcanvas-exit') : ''}`}
                style={{
                    backgroundColor: "white",
                    boxShadow: "0 1px 8px 0 rgba(0, 0, 0, 0.06)"
                }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><img
                        onClick={() => navigation("/")}
                        src="/img/logo2.jpg"
                        alt="logo-img"
                    /></Offcanvas.Title>
                </Offcanvas.Header>
                <div style={{ borderBottom: "0.5px solid #807575" }} className="mx-3"></div>
                <Offcanvas.Body style={{ padding: "0px" }}>

                    <div className="px-3 sidebar-navbar">
                        <ul className="sidebar-navbar-nav flex-column">
                            <li className="sidebar-nav-item" onClick={() => { navigation("/"); handleClose() }}>
                                <p className="sidebar-nav-link d-flex align-items-center"><IoHomeOutline
                                    style={{
                                        marginRight: "5px",
                                        cursor: "pointer",
                                    }}
                                />HOME</p>
                            </li>
                            {data.length > 0
                                ? data.map((item, i) => {
                                    return (
                                        <li className="sidebar-nav-item" onClick={() => {
                                            navigation(`/category/${item?.slug}_${item?.id}`); handleClose()
                                        }
                                        }>
                                            <p className="sidebar-nav-link d-flex align-items-center"><BiCategory
                                                style={{
                                                    marginRight: "5px",
                                                    cursor: "pointer",
                                                }} /> {item.name.toUpperCase()}</p>
                                        </li>
                                    );
                                })
                                : ""}

                            {userId ? (

                                <li className="sidebar-nav-item" onClick={() => { navigation("/profile"); handleClose() }}>
                                    <p className="sidebar-nav-link d-flex align-items-center"> <SlUser
                                        style={{
                                            marginRight: "5px",
                                        }}
                                    />PROFILE</p>
                                </li>

                            ) : (
                                <li className="sidebar-nav-item" onClick={() => { navigation("/login"); handleClose() }}>
                                    <p className="sidebar-nav-link d-flex align-items-center"> <SlUser
                                        style={{
                                            marginRight: "5px",
                                        }}
                                    />LOGIN</p>
                                </li>
                            )}
                            <li className="sidebar-nav-item" onClick={() => {
                                if (!userId) {
                                    setShowLoginModal(true);
                                    handleClose();
                                } else {
                                    navigation("/wishlist");
                                    handleClose();
                                }
                            }}>
                                <p className="sidebar-nav-link d-flex align-items-center justify-content-between"><span><CiHeart
                                    style={{
                                        marginRight: "5px",
                                    }}
                                />WISHLIST</span><span>{getWishLengthRedux !== 0 ? getWishLengthRedux : ""}</span></p>
                            </li>
                            <li className="sidebar-nav-item" onClick={() => {
                                if (!userId) {
                                    setShowLoginModal(true);
                                    handleClose();
                                } else {
                                    navigation("/cart");
                                    handleClose();
                                }
                            }}>
                                <p className="sidebar-nav-link d-flex align-items-center justify-content-between"><span><CiShoppingCart
                                    style={{
                                        marginRight: "5px",
                                    }}
                                />CART</span><span>{getCartLengthRedux !== 0 ? getCartLengthRedux : ""}</span></p>
                            </li>
                        </ul>
                    </div>


                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}


export default SidebarNavbar
