import axios from "axios";
import { baseUrl } from "../baseUrl";
import { SuccessToaster, ErrorToaster } from "../Toaster";

export const getTestimonialApi = (callback) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-testimonials`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {},
  };

  axios
    .request(config)
    .then((response) => {
      callback(response.data.data.testimonial_list);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getParentCategory = (language, callback) => {
  let data = JSON.stringify({
    language: language,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-parent-categories`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      callback(response.data.data.categories);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getAllProductsHomeApi = (
  getSelectedLanguage,
  getUserDetailRedux,
  setLoading,
  callback
) => {
  setLoading(true);
  let data = JSON.stringify({
    language: "en",
    uid: 8,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-products-home`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      setLoading(false);
      callback(response.data.data);
    })
    .catch((error) => {
      setLoading(false);
      console.log("error in getAllProductsHomeApi", error.response);
    });
};

export const getSingleProduct = (id, lang, callback) => {
  let data = JSON.stringify({
    productId: id,
    language: lang,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}single-product`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchCategoryProducts = (formdata, setLoading, callback) => {
  var data = JSON.stringify(formdata);
  setLoading(true);
  var config = {
    method: "post",
    url: `${baseUrl}fetch-category-products`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then((response) => {
      callback(response.data);
      setLoading(false);
    })
    .catch((error) => {
      console.log(error);
      setLoading(false);
    });
};

export const fetchChildCategoryProducts = (
  formdata,
  setChildSubCategory,
  callback
) => {
  var data = JSON.stringify(formdata);
  var config = {
    method: "post",
    url: `${baseUrl}fetch-child-category-products`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then((response) => {
      callback(response.data);
      console.log("hellloji");
    })
    .catch((error) => {
      console.log(error.response.data.message);
      if (error.response.data.message === "Child category not found") {
        setChildSubCategory([]);
      }
    });
};

export const fetchChildCategory = (formdata, callback) => {
  var data = JSON.stringify(formdata);

  var config = {
    method: "post",
    url: `${baseUrl}fetch-child-categories`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const Searchproduacts = (
  getSelectedLanguage,
  searchvalue,
  id,
  callback
) => {
  var data = JSON.stringify({
    page: 1,
    language: getSelectedLanguage,
    uid: id || "",
    search: searchvalue,
  });

  var config = {
    method: "post",
    url: `${baseUrl}search-product`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      callback(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const FetchAddressApi = (token, formdata, callback) => {
  let data = JSON.stringify(formdata);

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-all-address`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};
export const DeleteAddressApi = (
  token,
  addressId,
  FetchAddressApi,
  formdata,
  callback
) => {
  let data = JSON.stringify({ addressId });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}delete-address`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      SuccessToaster(response?.data?.message);
      FetchAddressApi(token, formdata, callback);
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);

      console.log(error);
    });
};
export const EditAddressApi = (
  token,
  input,
  FetchAddressApi,
  formdata,
  callback
) => {
  let data = JSON.stringify(input);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}update-address`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      SuccessToaster(response?.data?.message);
      FetchAddressApi(token, formdata, callback);
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);

      console.log(error);
    });
};

export const shippingApi = (token, payItem, callback) => {
  let data = JSON.stringify(payItem);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}shipping-cost`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      callback(response?.data);
    })
    .catch((error) => {
      console.log(error);
      ErrorToaster(error?.response?.data?.message?.error);
    });
};

export const checkAvailabilityApi = (sendData, callback) => {
  let data = JSON.stringify(sendData);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}track-delivery-service`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // SuccessToaster(response?.data?.message);

      callback(response?.data);
      // if (response.status === 'success') {

      // } else if (response.data.message.error === 'Unable to process request, Please contact: lastmile-integration@delhivery.com') {
      //   ErrorToaster("Unable to process request, Please contact: lastmile-integration@delhivery.com");
      // }
      // localStorage.removeItem("shipRates");
      // navigation("/");
      // setCartLengthRedux(0);
    })
    .catch((error) => {
      console.log(error);
      ErrorToaster(error?.response.data.message);
    });
};

export const payRazorPayApi = (token, payItem, callback) => {
  let data = JSON.stringify(payItem);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}pay/razorpay`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // SuccessToaster(response?.data?.message);
      callback(response?.data);
      // localStorage.removeItem("shipRates");
      // navigation("/");
      // setCartLengthRedux(0);
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);
      console.log(error);
    });
};

export const orderCreate = (token, payItem, navigation, setCartLengthRedux) => {
  let data = JSON.stringify(payItem);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}order-create`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      SuccessToaster("Order created successfully");
      console.log(response, "ordercreate");
      // localStorage.removeItem("shipRates");
      navigation("/");
      setCartLengthRedux(0);
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);
      console.log(error);
    });
};

export const orderIteamFetch = (token, formdata, callback) => {
  let data = JSON.stringify(formdata);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}order-items-fetch`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const orderCancelApi = (
  token,
  formdata,
  Data,
  setUpdateOrderDetailPage,
  updateOrderDetailPage
) => {
  let data = JSON.stringify(formdata);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}order-cancel`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // callback(response?.data?.status)
      SuccessToaster(response?.data?.message);
      setUpdateOrderDetailPage(!updateOrderDetailPage);
      // orderIteamFetch(token, Data);
      // navigation('/')
      // window.location.reload()
      //  console.log(response)
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);

      console.log(error);
    });
};

export const ContactusApi = (token, formdata, setInput) => {
  let data = JSON.stringify(formdata);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}contact-us`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
      // console.log(response);
      setInput({
        name: "",
        email: "",
        message: "",
      })
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);
      console.log(error);
    });
};

export const NotificationApi = (token, Formdata, callback) => {
  let data = JSON.stringify(Formdata);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-notifications`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // SuccessToaster(response?.data?.message);

      // console.log(response);
      callback(response?.data.data);
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);

      console.log(error);
    });
};

export const subscribeApi = (Formdata, setEmail) => {
  let data = JSON.stringify(Formdata);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}post-subscription`,
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
      setEmail("")
      // console.log(response);
      // callback(response?.data.data);
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.errors?.email[0]);

      // console.log(error.response.data.errors.email[0]);
    });
};


export const getBannerApi = async(id, callback)=>{
  let data = JSON.stringify({
    "category_id": id
  });
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseUrl}home-banner`,
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
    console.log(JSON.stringify(response.data));
    callback(response?.data)
  })
  .catch((error) => {
    console.log(error);
  });
}