import React from "react";
import "./../Styles/banner.css";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function Banner({ bannerImage}) {
  const navigation = useNavigate();

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    arrows: false,
    dots: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  console.log(bannerImage, 'bannerImagebannerImage')
  return (
    <>
    {
      !bannerImage || bannerImage ===undefined || bannerImage?.length===0
      ?
      null:
      <div className="container-fluid p-0">
      {
        bannerImage?.length === 1 || bannerImage?.length === '1'
          ?
          <img src="https://hasasi-backend.cyberx-infosystem.us/uploads/banners/1726568814.png" width='100%' alt='bannerImage' style={{ cursor: "pointer" }}></img>
          :
          <Slider {...settings} style={{ padding: "0px" }}>
            {
              bannerImage?.map((item, i) => {
                return <img key={i} src={item?.bannerImage} height='100%' width='100%' alt='bannerImage' style={{ cursor: "pointer" }} onClick={() => {
                  if (item?.categoryId && item?.catSlug) {
                    navigation(`/category/${item?.catSlug}_${item?.categoryId}`)
                  }
                }

                }></img>

              })
            }

          </Slider>
      }

    </div>
    }
    
    </>
  );
}

export default Banner;
